// This is for private multi-tenant setup only!
// It allows saving tenant-specific data to the Redux store on server.
// The data is then passed to frontend among preloaded state.

// ================ Action types ================ //

export const STORE_DATA = 'app/tenant/STORE_DATA';

// ================ Reducer ================ //

const initialState = {
  clientId: null,
  marketplaceName: null,
  marketplaceRootURL: null,
  stripePublishableKey: null,
  facebookClientId: null,
  googleClientId: null,
};

export default function tenantReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case STORE_DATA:
      return {
        ...state,
        ...payload,
      };

    default:
      return state;
  }
}

// ================ Action creators ================ //

export const storeData = data => ({
  type: STORE_DATA,
  payload: data,
});
